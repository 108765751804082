<template>
  <div style="height: 2.5rem; border-radius: 10px; z-index: 5" id="container">
    <div class="tools">
      <div>
        <span>经度:</span>
        <a-input
          placeholder="暂无数据"
          style="width: 60%; margin-left: 5%"
          v-model="form.Lng"
        ></a-input>
      </div>
      <div>
        <span>纬度:</span>
        <a-input
          placeholder="暂无数据"
          style="width: 60%; margin-left: 5%"
          v-model="form.Lat"
        ></a-input>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import api from "../api.js";
export default {
  components: {},
  data() {
    return {
      map: null,
      mouseTool: null,
      isShow: true,
      form: {
        Lng: "",
        Lat: "",
      },
    };
  },
  watch: {},

  methods: {
    initAMap() {
      const self = this; // 存储当前的this
      AMapLoader.load({
        key: "6636defcef28a2bf1f1043f154835db6", // 申请好的Web端开发者Key，首次调用 load 时必填
        // version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        version: "2.0",
        plugins: ["AMap.MouseTool"],
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          self.map = new AMap.Map("container", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            zoom: 15, // 初始化地图级别
            terrain: true, //开启地形图
            center: [117.303216, 31.803165], // 初始化地图中心点位置
          });
          self.map.on("click", function (e) {
            self.map.clearMap();
            self.form.Lng = e.lnglat.getLng();
            self.form.Lat = e.lnglat.getLat();
            //创建一个 点位实例：
            const marker = new AMap.Marker({
              position: new AMap.LngLat(self.form.Lng, self.form.Lat), //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: "场所",
            });
            //将创建的点标记添加到已有的地图实例：
            self.map.add(marker);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retPoint() {
      return this.form;
    },

    // 卸载地图
    unloadMap() {
      if (this.map) {
        this.map.clearMap();
      }
      this.form = { Lng: "",Lat: ""}
    },
  },

  created() {},

  mounted() {
    this.initAMap();
  },
  beforeDestroy() {
    this.unloadMap();
  },
};
</script>
<style lang='less' scoped>
#container {
  position: relative;

  .tools {
    position: absolute;
    left: 3%;
    top: 5%;
    z-index: 99990;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 8%;
    width: 30%;
    display: flex;
    div {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 700;
      }
    }
  }
}
</style>
